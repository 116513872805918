import { RVTask } from "@regal-voice/shared-types";
import { useSelector } from "react-redux";

import { wrapTask } from "Services/ConversationsApiService";
import { getLogger } from "Services/LoggingService";
import { useRVDispatch } from "Services/state/Storage";
import { selectTasks } from "Services/state/tasks/Selectors";
import { updateExistingTask } from "Services/state/tasks/Thunks";

const logger = getLogger("Twilio Conferences");

export function useWrapTask(): (taskSid: string) => Promise<void> {
    const tasks = useSelector(selectTasks);
    const dispatch = useRVDispatch();

    return async function handleWrapping(taskSid: string) {
        try {
            const response = await wrapTask({ taskSid });
            // bug bash clean up, not sure why we are asserting here
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const [currentTask] = Object.values(tasks).filter((task) => task!.taskSid == taskSid);

            // success will only be truthy if the `wrap-task` endpoint successfully updated the task status to wrapping
            if (response?.success && currentTask) {
                const updatedTask = {
                    ...currentTask,
                    taskStatus: "wrapping",
                    status: "wrapping",
                } as RVTask;

                dispatch(updateExistingTask(updatedTask));
                logger.log("Task successfully wrapped after call ended", { taskSid });
            }
            // update type to unknown and use normalizeError
        } catch (err: any) {
            logger.error(err, { taskSid });
        }
    };
}
